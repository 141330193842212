<template>
  <div>
    <template v-if="data">
      <Component :is="getResolvedComponent(data.borderShape)" :data="imageData" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { BorderShape, SimpleImage } from "~/types/strapi/components/layout-sections/interfaces/SimpleImage";

const props = defineProps<{
  data?: SimpleImage;
}>();

const getResolvedComponent = (shape: BorderShape) => {
  switch (shape) {
    case "circle":
      return resolveComponent("LazyImageCircleImage");
    case "square":
      return resolveComponent("LazyImageSquareImage");
    case "landscape":
      return resolveComponent("LazyImageLandscapeImage");
  }
};

const imageData = computed(() => {
  if (props.data?.borderShape === "circle") {
    return props.data.image;
  }

  return props.data;
});
</script>

<style lang="scss" scoped></style>
